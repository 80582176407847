import Modal from 'react-modal';
import { useModal } from '../../hooks/useModal';
import CloseIcon from '../../assets/icons/CloseIcon';
import s from './styles.module.scss';
import { useMediaQuery } from '../../hooks/useMediaQuery';

Modal.setAppElement('#root');

const CustomModal = () => {
  const { modalIsOpen, closeModal, ContentComponent, withCloseIcon } = useModal();
  const isMobile = useMediaQuery('(max-width: 540px)');

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Custom Modal"
      style={{
        overlay: {
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgba(0, 0, 0, 0.6)',
          zIndex: 20,
        },
        content: {
          overflow: 'auto',
          outline: 'none',
          boxShadow: '0 8px 25px rgba(83, 89, 144, 0.12)',
          background: '#fff',
          padding: '19px 32px',
          inset: '0 0 0 auto',
          border: 'none',
          width: '100%',
          maxWidth: isMobile ? '100%' : '355px',
          right: '0px',
          height: '100%',
        },
      }}
    >
      {withCloseIcon && (
        <span className={s.close} onClick={closeModal}>
          <CloseIcon />
        </span>
      )}
      <ContentComponent />
    </Modal>
  );
};

export default CustomModal;
