import { FC } from 'react';

const CloseIcon: FC = () => {
  return (
    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2.51562"
        y="15.4307"
        width="22"
        height="2"
        rx="1"
        transform="rotate(-45 2.51562 15.4307)"
        fill="#323232"
      />
      <rect
        x="18.0703"
        y="16.8447"
        width="22"
        height="2"
        rx="1"
        transform="rotate(-135 18.0703 16.8447)"
        fill="#323232"
      />
    </svg>
  );
};

export default CloseIcon;
