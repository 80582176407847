// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_close__SX5rF {
  display: flex;
  justify-content: end;
}
.styles_close__SX5rF svg {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,oBAAA;AACJ;AAAI;EACI,eAAA;AAER","sourcesContent":[".close {\r\n    display: flex;\r\n    justify-content: end;\r\n    svg {\r\n        cursor: pointer;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": `styles_close__SX5rF`
};
export default ___CSS_LOADER_EXPORT___;
