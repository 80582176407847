import { Route, Routes } from 'react-router-dom';
import './App.scss';
import React from 'react';
import CustomModal from './components/modal';

const Layout = React.lazy(() => import('./components/layout/Layout'));
const Home = React.lazy(() => import('./components/home/Home'));
const Tenq = React.lazy(() => import('./components/tenq/Tenq'));
const Dar = React.lazy(() => import('./components/dar/Dar'));

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/tenq" element={<Tenq />} />
          <Route path="/dar" element={<Dar />} />
        </Route>
      </Routes>
      <CustomModal />
    </div>
  );
};

export default App;
