import React, { createContext, useContext, useEffect, useState } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [withCloseIcon, setWithCloseIcon] = useState(false);
  const [ContentComponent, setContentComponent] = useState(() => () => null);

  const openModal = (Component) => {
    setContentComponent(() => Component);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const closeIcon = () => setWithCloseIcon(true);

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalIsOpen]);

  return (
    <ModalContext.Provider
      value={{ modalIsOpen, openModal, closeModal, ContentComponent, closeIcon, withCloseIcon }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
